import React from "react";
import { Link } from "gatsby";

const MenuMobile = props => {
  //const { menuLinks } = props.data.site.siteMetadata;
  return (
    <div
      id="main-menu-mobile"
      className={`main-menu-mobile ${props.active ? "open" : ""}`}
    >
      <ul>
        <li className="nav-item">
          <Link className="nav-link" to="/">
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/breed">
            Our Breed
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/difference">
            Our Difference
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/history">
            Our History
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/beliefs">
            Beliefs
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/producers">
            Producers
          </Link>
        </li>
        {/*<li className="nav-item"><Link className="nav-link" to="/partners">Partners</Link></li>*/}
        <li className="nav-item">
          <Link className="nav-link" to="/people">
            People
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/genetics">
            Genetics
          </Link>
        </li>
        {/* <li className="nav-item"><Link className="nav-link" to="/media">Media</Link></li> */}
        <li className="nav-item">
          <Link className="button btn-primary" to="/contact">
            Contact Us
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default MenuMobile;
//export default props => (
//  <StaticQuery
//    query={graphql`
//      query MenuMobileQuery {
//        site {
//          siteMetadata {
//            menuLinks {
//              name
//              link
//            }
//          }
//        }
//      }
//    `}
//    render={data => <MenuMobile active={props.active} data={data} />}
//  />
//);
