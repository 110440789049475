import React from "react";
// import { graphql, StaticQuery, Link } from 'gatsby';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
// import 'bootstrap/js/dist/util';
// import 'bootstrap/js/dist/dropdown';

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    return (
      <div id="main-menu" className="main-menu">
        <ul>
          <Dropdown
            className="about-us"
            nav
            isOpen={this.state.dropdownOpen}
            toggle={this.toggle}
          >
            <DropdownToggle nav caret>
              About SPKL
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem tag="a" href="/breed">
                Our Breed
              </DropdownItem>
              <DropdownItem tag="a" href="/difference">
                Our Difference
              </DropdownItem>
              <DropdownItem tag="a" href="/history">
                Our History
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <li className="nav-item">
            <a className="nav-link" href="/beliefs">
              Beliefs
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/producers">
              Producers
            </a>
          </li>
          {/*<li className="nav-item"><a className="nav-link" href="/partners">Partners</a></li>*/}
          <li className="nav-item">
            <a className="nav-link" href="/people">
              People
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/genetics">
              Genetics
            </a>
          </li>
          {/* <li className="nav-item"><a className="nav-link" href="/media">Media</a></li> */}
          <li className="nav-item contact-us">
            <a className="nav-link" href="/contact">
              CONTACT US
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Menu;
//export default props => (
//    <StaticQuery
//        query={graphql`
//      query SiteTitleQuery {
//        site {
//          siteMetadata {
//            menuLinks {
//              name
//              link
//            }
//          }
//        }
//      }
//    `}
//        render={data => <Menu data={data} />}
//    />
//);
