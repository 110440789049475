import React from "react";
import team001 from "../../images/t1.jpg";
// import { Link } from 'gatsby-plugin-modal-routing'

import ConditionalLayout from "../../components/ConditionalLayout";
// import SEO from "../../components/seo"

const SecondPage = () => (
  <ConditionalLayout>
    <h3 className="title-3">Peter Colley</h3>
    <h4 className="">Chairman</h4>
    <div className="card">
      <div className="card-body">
        <div className="team-img">
          <img alt="About team" className="img-fluid" src={team001} />
        </div>
        <p>
          Peter founded the QC Communications Group of companies in 1997 and
          held the positions of Managing Director and CEO during his tenure
          until recently handing over control to son Steve.
        </p>
        <p>
          Peter is still actively involved with the Group as consultant and
          shareholder. The QC Group has become a successful Telecommunications
          Construction Company securing major contracts with NBN, Telstra,
          Optus, TPG and many other carriers. Since 2014 the company has been
          one of NBN’s select major contractors building the NBN network.
        </p>
        <p>
          Peter’s interest in cattle breeding was crystallised in 2016 when he
          commenced operating Greenhaven Speckle Park Pty Ltd, a successful
          purebred Speckle Park Cattle breeding operation on the NSW Mid North
          Coast.{" "}
        </p>
        <p>
          More recently Peter become a shareholder and founding board member of
          Speckle Park Group Ltd, whose branded product SPKL is currently being
          released. Peter is also a shareholder and board member of Global Elite
          Speckle Park Pty Ltd.
        </p>
      </div>
    </div>
  </ConditionalLayout>
);

export default SecondPage;
